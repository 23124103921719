<template>
  <base-layout>
    <ion-searchbar show-cancel-button="focus" @ionChange="filter(search)" :value="search"
                   @ionInput="search = $event.target.value;"></ion-searchbar>

    <ion-content class="has-header has-subheader">
      <div class="p-3" v-if="searchDone  && completeResults == 0">
        We're sorry, we didn't find a search result for "{{ search }}".
        Please try again.
      </div>
      <div class="container-fluid p-0 p-md-2">
        <!--div class="row row-cols-1 row-cols-md-4 row-cols-lg-6 ps-2 ps-md-2 g-0 g-md-3 row-eq-height justify-content-start">
          <template v-for="person in filteredPersons" :key="person.id">
            <person-item :person="person" v-if="person" ></person-item>
          </template>
        </div-->
        <div
            class="row g-md-3 row-eq-height justify-content-start d-none d-md-flex">
          <template v-for="person in filteredPersons" :key="person.id">
            <div class="col person-wrapper" v-if="!person.isLetter">
              <person-item :person="person"></person-item>
            </div>
            <div class="" v-else>
              <person-item :person="person"></person-item>
            </div>

          </template>
        </div>
        <div
            class="row row-cols-1 row-cols-md-4 g-2 ps-md-2 g-md-3 row-eq-height justify-content-start d-block d-md-none">
          <template v-for="person in filteredPersons" :key="person.id">
            <person-item :person="person"></person-item>
          </template>
        </div>
      </div>

      <ion-infinite-scroll
          @ionInfinite="increaseLimit($event)"
          threshold="100px"
          id="infinite-scroll"
          :disabled="isDisabled"
      >
        <ion-infinite-scroll-content
            loading-spinner="bubbles"
            loading-text="Loading more data...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </base-layout>
</template>
<script>
import {IonContent, IonSearchbar, IonInfiniteScroll, IonInfiniteScrollContent} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapActions, mapState} from "vuex";
import PersonItem from "@/components/PersonItem";

export default defineComponent({
  name: "Home",
  data() {
    return {
      persons: [],
      search: "",
      filtered: [],
      limit: 50,
      letters: {},
      searchDone: false,
      completeResults: 0,
    };
  },
  components: {
    IonContent,
    IonSearchbar,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    PersonItem
  },
  methods: {
    ...mapActions("persons", ["getAllPersons"]),
    filter(term) {
      if (term.length > 0) {

        this.filtered = this.persons.filter(
            person => Object.keys(person).some(k => {
              return (person[k] && typeof person[k] == 'string' ? person[k].toLowerCase().includes(term.toLowerCase()) : '')
            })
        ).sort((a, b) => {
          return a.last_name.localeCompare(b.last_name);
        });
        this.searchDone = true;
        this.completeResults = this.filtered.length;
      } else {
        this.filtered = [];
        this.searchDone = false;
        this.completeResults = 0;
      }
    },
    increaseLimit(ev) {
      setTimeout(() => {
        this.limit += 50;
        console.log('Loaded data');
        ev.target.complete();

        if (this.persons.length <= this.limit) {
          ev.target.disabled = true;
        }
      }, 500);
    },

    groupList(list, selector) {
      let letters = [];
      let currentCharCode = 'A'.charCodeAt(0) - 100;
      let sortedList = [];

      function addLetter(code) {
        let letter = String.fromCharCode(code);
        sortedList.push({
          isLetter: true,
          letter: letter
        });
        letters.push(letter);
      }

      (list || []).forEach(function (item) {
        if (item[selector]) {
          let itemCharCode = item[selector].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().charCodeAt(0);
          let difference = itemCharCode - currentCharCode;
          if (difference > 0)
            addLetter(itemCharCode);
          currentCharCode = itemCharCode;
          sortedList.push(item);
        }
      });

      return sortedList;
    }

  },
  computed: {
    ...mapState('wordings', ['wordings', 'currentLanguage']),

    filteredPersons: function () {
      let returnList = this.searchDone ? this.filtered : this.persons;
      return this.groupList(returnList.slice(0,this.limit), 'last_name');
    }
  },
  async created() {
    this.persons = (await this.getAllPersons()).sort((a, b) => {
      if (a.last_name) { //eine Person hatte keinen Vor- oder Nachnamen, ich habe ein if angegeben, um den undefined zu vermeiden
        return a.last_name.localeCompare(b.last_name);
      }
    });
    this.persons = this.groupList(this.persons, 'last_name');

  }
});
</script>
<style lang="scss">
.person-wrapper {
  max-width: 200px;
}
</style>
